.ql-mention-list-container {
  background: white;
  padding: 4px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 200px;
  overflow: scroll;
  width: auto;
  min-width: 200px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 999
}

.ql-mention-list-container::-webkit-scrollbar {
  display: none;
}

ul.ql-mention-list {
  padding: 0;
}

li.ql-mention-list-item {
  padding: 10px;
}

li.ql-mention-list-item:first-of-type {
  border-radius: 4px 4px 0 0;
}

li.ql-mention-list-item:last-of-type {
  border-radius: 0 0 4px 4px;
}

li.ql-mention-list-item:hover,
li.ql-mention-list-item.selected {
  background: #f4f5f8;
}

.quill,
.quill .ql-container {
  display: flex;
  flex-direction: column;
}

.quill .ql-container,
.quill .ql-editor {
  overflow: visible;
  flex: 1;
  font-family: 'Inter';
  font-size: 16px;
}

.quill .ql-editor {
  width: '100%';
  max-width: 550px;
  overflow-wrap: break-word;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.quill .ql-editor::-webkit-scrollbar {
    display: none;
}

.quill .ql-editor p a {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  /* fix for links pasted into editor on mobile safari */
  white-space: pre-wrap;     
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap;      
  white-space: -o-pre-wrap;    
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  color: #999A9C;
}

.ql-container.ql-bubble:not(.ql-disabled) a
{
  white-space: pre-wrap !important;
}